import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
import { graphql } from "gatsby";
import Article from "../components/photoessay/article";
import Banner from "../components/photoessay/banner";
import Photogrid from "../components/photoessay/photogrid";
import Image from "../components/photoessay/image";
import PhotoessayStyles from "../components/photoessay/photoessay.module.scss";

require('typeface-cardo');

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Metatags title="Photoessay | May 20 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-20.png" url="https://cssgrid31.brett.cool" pathname="/may-20" mdxType="Metatags" />
    <div className={PhotoessayStyles.container}>
  <Banner title="Arashiyama" subtitle="and a Monkey Park" file="banner" mdxType="Banner" />
      <p>{`We emerged from our Hotel to find it lightly snowing.`}</p>
      <p>{`Walking through the gentle snowfall, we made our way towards Karasuma subway station.`}</p>
      <Photogrid design="singleRight" mdxType="Photogrid">
  <Image filename="P2101453.jpg" alt="A photo" mdxType="Image" />
      </Photogrid>
      <p>{`We rode the subway to Kyoto station.`}</p>
      <p>{`Picking up an onigiri en route from a convenience store station, we eventually found our way to the JR office, and activated our JR pass.`}</p>
      <p>{`We then used our pass to board a train bound for Arashiyama.`}</p>
      <Photogrid design="fourUp" mdxType="Photogrid">
  <Image filename="P2101464.jpg" alt="Station platform" mdxType="Image" />
  <Image filename="P2101466.jpg" alt="View from the train" mdxType="Image" />
  <Image filename="P2101468.jpg" alt="Another view from the train" mdxType="Image" />
  <Image filename="P2101477.jpg" alt="Neighbourhood" mdxType="Image" />
      </Photogrid>
      <p>{`Within 20 minutes, we arrived at Arashiyama station. We made our way south, walking through quiet streets.`}</p>
      <Photogrid design="twoSide" mdxType="Photogrid">
  <Image filename="P2101483.jpg" alt="JR Station" mdxType="Image" />
  <Image filename="P2101487.jpg" alt="Crowds walking" mdxType="Image" />
      </Photogrid>
      <p>{`As we headed south towards Katsura River, it began to snow lightly.`}</p>
      <Photogrid design="twoSide" mdxType="Photogrid">
  <Image filename="P2101493.jpg" alt="Snowing" mdxType="Image" />
  <Image filename="P2101507.jpg" alt="Snowing" mdxType="Image" />
      </Photogrid>
      <p>{`We headed across Togetsu-kyō bridge.`}</p>
      <Photogrid design="singleRight" mdxType="Photogrid">
  <Image filename="P2101527.jpg" alt="Across the bridge" mdxType="Image" />
      </Photogrid>
      <p>{`We continued south, admiring the beauty of the rivers and mountains.`}</p>
      <Photogrid design="twoSide" mdxType="Photogrid">
  <Image filename="P2101534.jpg" alt="River views" mdxType="Image" />
  <Image filename="P2101532.jpg" alt="River views" mdxType="Image" />
      </Photogrid>
      <p>{`Following the coast, we made our way west towards the monkey park.`}</p>
      <Photogrid design="singleRight" mdxType="Photogrid">
  <Image filename="P2101540.jpg" alt="Across the bridge" mdxType="Image" />
      </Photogrid>
      <p>{`Last time I’d visited, it had been closed due to snow. Today however, it was open for business.`}</p>
      <p>{`We decided to make our way up the mountain.`}</p>
      <Photogrid design="twoSide" mdxType="Photogrid">
  <Image filename="P2101542.jpg" alt="Climbing the mountain" mdxType="Image" />
  <Image filename="P2101546.jpg" alt="Climbing the mountain" mdxType="Image" />
      </Photogrid>
      <p>{`We continued up the mountain, beautiful aerial views of Arashiyama coming into view as we continued our climb.`}</p>
      <Photogrid design="twoSide" mdxType="Photogrid">
  <Image filename="P2101547.jpg" alt="Climbing the mountain" mdxType="Image" />
  <Image filename="P2101553.jpg" alt="Climbing the mountain" mdxType="Image" />
      </Photogrid>
      <p>{`Eventually we approached the summit.`}</p>
      <Photogrid design="singleRight" mdxType="Photogrid">
  <Image filename="P2101555.jpg" alt="Approaching the summit" mdxType="Image" />
      </Photogrid>
      <p>{`And we began to spot Monkeys.`}</p>
      <Photogrid design="twoSide" mdxType="Photogrid">
  <Image filename="P2101556.jpg" alt="Monkey spotting" mdxType="Image" />
  <Image filename="P2101558.jpg" alt="Monkey spotting" mdxType="Image" />
      </Photogrid>
      <p>{`We continued to the observation deck, offering beautiful, panoramic views of Arashiyama.`}</p>
      <Image filename="banner.jpg" alt="Banner image" mdxType="Image" />
      <Photogrid design="twoSide" mdxType="Photogrid">
  <Image filename="P2101564.jpg" alt="Monkey spotting" mdxType="Image" />
  <Image filename="P2101570.jpg" alt="Monkey spotting" mdxType="Image" />
      </Photogrid>
    </div>
    <Footer date={20} prev={true} next={true} mdxType="Footer">
      <p>{`One of my favourite projects in the past few years has been putting together `}<a parentName="p" {...{
          "href": "https://brett.cool/photoessays/"
        }}>{`Photoessays`}</a>{` for travels and other events.`}</p>
      <p>{`I can certainly see a lot of potential with Gatsby, MDX, and CSS Grid to enhance future Photoessays.`}</p>
      <p>{`I also tried using `}<a parentName="p" {...{
          "href": "https://www.gatsbyjs.org/packages/gatsby-image/"
        }}>{`gatsby-image`}</a>{` for this layout. I quite like the way it automates the process of optimising images, but unfortunately it’s a bit inflexible for positioning and scaling.`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      